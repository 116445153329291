"use client";

import { motion } from "framer-motion";
import Link from "next/link";
import React from "react";
import { AuroraBackground } from "@/components/ui/aurora-background";

import { Button } from "@/components/ui/button";
import Header from "../../shared/Header";
import Tag from "../../shared/Tag";
import Image from "next/image";
import PulsatingButton from "@/components/magicui/pulsating-button";

const CTA = () => {
  return (
    <AuroraBackground className="m-3 rounded-[20px] bg-[url('/ctaBg.jpg')] bg-cover bg-center py-12 md:m-5 md:rounded-[40px]">
      <motion.div
        initial={{ opacity: 0.0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          delay: 0.3,
          duration: 0.8,
          ease: "easeInOut",
        }}
        className="relative mx-auto space-y-10 rounded-[20px] px-4 py-8 pt-48 text-center md:rounded-[40px]"
      >
        <div className="mx-auto w-full space-y-4">
          <h1 className="text-[36px] leading-[120%] text-white md:text-[64px]">
            Make your first video in seconds.
          </h1>
          <h5 className="text-[21px] text-white md:text-[28px]">
            Clipping has never been easier. Try it now.
          </h5>
        </div>
        <Image
          src="https://crayo-lp.vercel.app/footer-lightning.png"
          alt="lightning"
          className="absolute left-[15%] top-0 md:left-[40%]"
          width={272}
          height={272}
        />
        <div className="inline-flex flex-col space-y-5">
          <Link
            href="https://crayo.ai/dashboard"
            className="align-center z-20 flex items-center justify-center rounded-[21px] border-[5px] border-white/10 bg-transparent shadow-[0_13px_22px_rgba(0,0,0,0.10)]"
          >
            {/* <Button 
                  variant="secondary" 
                  size="lg" 
                  className="py-4 bg-crayoBlue hover:bg-crayoBlue/70 inline-flex border-5 border-[#ffffff20] items-center space-x-2.5 shadow-[0_13px_22px_rgba(0,0,0,0.10)]"
                  >
                      <Image src="/lightningWhite.png" alt="Lightning" width={16} height={16} />
                      <span className="text-white font-ambit-bold">Start using Crayo</span>
                  </Button> */}
            <PulsatingButton>
              <Image
                src="https://crayo-lp.vercel.app/lightningWhite.png"
                alt="Lightning"
                width={16}
                height={16}
              />
              <span className="font-ambit-bold text-white">Start using Crayo</span>
            </PulsatingButton>
          </Link>
          <Link href="https://crayo.ai/discord" className="text-white">
            Join our Discord
          </Link>
        </div>
      </motion.div>
    </AuroraBackground>
  );
};
export default CTA;
